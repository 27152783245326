// eslint-disable-next-line import/prefer-default-export
export const georgiaHero = {
  title:
    "Bringing big-box efficiency to Georgia’s heart-and-soul small businesses",
  subtext:
    "As an association member, you can choose how you want to save with SpotOn. Either get a credit towards your first POS station or receive a free consultation, set up, and trial of SpotOn Reserve or Spoton's labor management solution. Click to schedule a demo to learn more.",

  heroImg: "Georgia.png",
  imageClassName: "fixed-height-600 top-adjustement",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/associations/demo",
  },
  video: {
    videoBtn: "Play video",
    posterWrapperClass: "",
    posterImg: "hero-video-image.png",
    videoUrlId: "hLlIUkFESiw",
  },
};

export const georgiaSeal = {
  title: "Georgia Restaurant Association:",
  img: "GRA_Logo.png",
  text: "SpotOn Named the Preferred Technology Partner of the Georgia Restaurant Association",
  url: "https://www.garestaurants.org/news/spoton-named-the-preferred-technology-partner-of-the-georgia-restaurant-association",
};

export const georgiaLargeFeatures = {
  featureBlocks: [
    {
      blockTitle: "Online ordering, delivery & dine-in ordering",
      blockImg: "large-phone.png",
      blockSubTitle:
        "With digital solutions for take-out, delivery, and on-premise dining, SpotOn helps increase throughput to boost revenue with significant cost savings compared to third-party services.",
      blockList: [
        "Online ordering from your website",
        "Mobile dine-in ordering",
        "SpotOn Delivery, powered by DoorDash",
        "E-Gift cards",
        "Direct POS integration",
        "Own your guest's data",
      ],
      id: "oo",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Waitlisting, reservations, and table management",
      blockSubTitle:
        "Book more reservations, turn tables faster, and increase profits while creating a seamless experience for your guests. Book more, serve more and earn more.",
      blockImg: "reservation_confirmed.png",
      blockList: [
        "24-hour online booking",
        "Waitlisting & real-time notifications",
        "Server & table management",
        "Reserve with Google listing",
        "No per-cover fees",
        "POS integration or standalone front-of-house",
        "Optional vaccination status checkbox",
      ],
      id: "rct",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Contactless payments & ordering",
      blockImg: "contactless-payments.png",
      blockSubTitle:
        "Keep up with changing guest expectations and streamline the order and pay process to serve more guests. With SpotOn, the checkout process will be moving faster than ever before.",
      blockList: [
        "Handheld POS",
        "QR code on receipt",
        "Credit card terminal",
        "Customer-facing displays",
        "Mobile dine-in ordering",
        "Mobile card readers",
      ],
      id: "cp",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Digital marketing & loyalty",
      blockImg: "Marketing.png",
      blockSubTitle:
        "Grow your business by connecting with our customers online, in-person, and on their mobile phones. SpotOn’s built-in tools make it easy to attract new customers and keep your regulars coming back.",
      blockList: [
        "Marketing campaigns",
        "Loyalty rewards",
        "Online review management",
        "Web dashboard",
        "Mobile dashboard app",
        "Guest and campaign data & insights",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
  ],
};
export const georgiaRestaurantTypes = [
  {
    content: "Casual Full-Service",
    desc: "Turn tables faster while creating a top-notch experience that lets guests order and pay their way.",
    img: "casual_fs.png",
  },
  {
    content: "Bars & Nighclubs",
    desc: "Streamline operations behind the bar and on the floor, and monitor bar stations remotely.",
    img: "bars_clubs.png",
  },
  {
    content: "Quick Service",
    desc: "Speed up the checkout process with built-in deal redemptions and loyalty rewards to drive repeat traffic.",
    img: "quick-serve.png",
  },
  {
    content: "Enterprise",
    desc: "Manage menus and labor across multiple location, with real-time customer insights and data.",
    img: "enterprise.png",
  },
  {
    content: "Pizzeria",
    desc: "Simplify operations, including delivery, while giving your customers multiple ways to order and pay.",
    img: "pizzeria.png",
  },
  {
    content: "Fine Dining",
    desc: "Create a VIP-level experience and boost efficiency with integrated reservations, waitlisting, and point-of-sale.",
    img: "fine-dine.png",
  },
];

export const georgiaTestimonialsData = [
  {
    testimonial: `“SpotOn’s local support has always been super responsive and made the transition from our outdated POS simple and easy”`,
    client: ``,
    company: `Miss Goggi`,
    imageOne: `mgoggi2.png`,
    imageTwo: `mgoggi1.png`,
    imageThree: `mgoggi3.png`,
  },
  {
    testimonial: `“I can't say enough good about this company and software. Ease of Use and Integration are "SpotOn", and the customer service is a true breath of fresh air!!!”`,
    client: ``,
    company: `Riley's`,
    imageOne: `rileys1.png`,
    imageTwo: `rileys3.png`,
    imageThree: `rileys2.jpeg`,
  },
  {
    testimonial: `“SpotOn is the best point of sale system that I have ever worked with. I have worked with many different systems and I can say SpotOn has all you need for a restaurant.”`,
    client: `Joseph Lozada `,
    company: `El Don Mexican`,
    imageOne: `el_don1.png`,
    imageTwo: `el_don2.png`,
    imageThree: `el_don3.png`,
  },
];

export const georgiaIconTemplate = [
  {
    content: "Front-of-house",
    desc: "Create an unforgettable guest experience & streamline operations",
    img: "book-open.png",
  },
  {
    content: "Back-of-house",
    desc: "Update menus, pricing specials, and team members in real-time",
    img: "urgent.png",
  },
  {
    content: "Handheld POS & mobile ordering",
    desc: "Ditch the wires to better serve guests and turn tables faster",
    img: "calc.png",
  },
  {
    content: "Real-time guest data & reporting",
    desc: "Get the insights you need to adapt quickly, including right from your phone",
    img: "pie-chart.png",
  },
  {
    content: "Third-party integrations",
    desc: "Connect your POS to the other software and tools you rely on",
    img: "git-merge.png",
  },
  {
    content: "Face-to-face installation & training",
    desc: "Get your staff started right with help from real-life human experts",
    img: "award.png",
  },
  {
    content: "Online ordering",
    desc: "If you’re looking for quick, easy order and pay options that are a cut above your competitors, SponOn’s point-of-sale solutions can deliver all that plus loyalty programs that work",
    img: "online-purchase.png",
  },
  {
    content: "Reservations",
    desc: "Easily manage your waitlist and reservation system in real time with the SpotOn Reserve front-of-house solution.",
    img: "reservation.png",
  },
];

export const georgiaXL = {
  mainTitle: "Proudly serving businesses across Georgia",
  subtext:
    "Unlike other companies that send you a box in the mail with your equipment and put you on hold for hours, SpotOn has a team of local professionals to help you. They are trusted members of their local business communities, all across Georgia, and are here to put your business first.",
  phoneNumber: "+18882246506",
  phoneText: "(888) 224-6506",
  email: "hospitality@spoton.com",
};

export const georgiaWhiteGlove = {
  mainTitle: "Real-life support. Whenever you need it",
  title: "Service and Support",
  content:
    "Your business deserves the best professional support you can get. That’s why we provide local service with in-person installation and training, backed by 24/7/365 technical support. ",
  contentList: [
    "Face-to-face hardware installation",
    "In-person staff training",
    "Virtual training whenever you need it",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/associations/demo",
  },
};
